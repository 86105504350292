import React from "react";
import "./style.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//icons
import { ReactComponent as PartnerIcon } from "../../assets/partner_exchange.svg";
import { ReactComponent as HomeIcon } from "../../assets/home.svg";
import { ReactComponent as NatureIcon } from "../../assets/nature.svg";
import { ReactComponent as LocationIcon } from "../../assets/location_city.svg";
import { ReactComponent as EventIcon } from "../../assets/event_available.svg";
import { ReactComponent as MonetizationIcon } from "../../assets/monetization_on.svg";
import { ReactComponent as BalanceIcon } from "../../assets/balance.svg";
import { ReactComponent as NoteIcon } from "../../assets/note_alt.svg";
import { ReactComponent as PrevButtonIcon } from "../../assets/expand_circle_left.svg";
import { ReactComponent as NextButtonIcon } from "../../assets/expand_circle_right.svg";
import IconButton from "@mui/material/IconButton";
import Setores from "../Setores/Setores";

const Atuacao = () => {
  const handleDragStart = (e: any) => e.preventDefault();

  const PrevButton = ({ onClick }: any) => {
    return (
      <IconButton
        className="iconButton"
        style={{
          right: "200px",
        }}
        onClick={onClick}
      >
        <PrevButtonIcon className="prevButton" />
      </IconButton>
    );
  };
  const NextButton = ({ onClick }: any) => {
    return (
      <IconButton
        className="iconButton"
        style={{
          right: "100px",
        }}
        onClick={onClick}
      >
        <NextButtonIcon className="prevButton" />
      </IconButton>
    );
  };

  const MultiItemCarousel = () => {
    return (
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={1500}
        autoPlay
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass="react-multi-carousel-item"
        keyBoardControl
        minimumTouchDrag={80}
        customLeftArrow={<PrevButton />}
        customRightArrow={<NextButton />}
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={true}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 4,
            partialVisibilityGutter: 40,
          },          
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        <div
          id="carousel_card_01"
          className="carousel_card"
          onDragStart={handleDragStart}
        >
          <PartnerIcon />
          <h4>Parceria Estratégica</h4>
          <div className="atuacao_carousel_card_text">
            Atuação em novos negócios de empresas estatais ou de capital misto.
          </div>
        </div>
        <div
          id="carousel_card_02"
          className="carousel_card"
          onDragStart={handleDragStart}
        >
          <HomeIcon />
          <h4>Infraestrutura</h4>
          <div className="atuacao_carousel_card_text">
            Atuação e desenvolvimento de projetos de infraestrutura,
            proporcionando inovações diante dos desafios do mercado.
          </div>
        </div>
        <div
          id="carousel_card_03"
          className="carousel_card"
          onDragStart={handleDragStart}
        >
          <NatureIcon />
          <h4>ESG</h4>
          <div className="atuacao_carousel_card_text">
            Estruturação de práticas estratégicas ESG em empresas e governo.
          </div>
        </div>
        <div
          id="carousel_card_04"
          className="carousel_card"
          onDragStart={handleDragStart}
        >
          <LocationIcon />
          <h4>PPP's e Concessões</h4>
          <div className="atuacao_carousel_card_text">
            Consultas sobre Parcerias Público Privadas e Concessões, nos setores
            tradicionais ou emergentes, atuando da estruturação à gestão dos
            projetos.
          </div>
        </div>
        <div
          id="carousel_card_05"
          className="carousel_card"
          onDragStart={handleDragStart}
        >
          <EventIcon />
          <h4>Verificação Independente</h4>
          <div className="atuacao_carousel_card_text">
            Gestão independente de contratos de PPP's e Concessões com
            acompanhamento de metas, indicadores e avaliação de resultados.
          </div>
        </div>
        <div
          id="carousel_card_06"
          className="carousel_card"
          onDragStart={handleDragStart}
        >
          <MonetizationIcon />
          <h4>Financiamento de Projetos</h4>
          <div className="atuacao_carousel_card_text">
            Estruturação financeira de projetos, por meio de ferramentas de
            project finance e outros modelos de captação de recursos para
            projetos de parcerias.
          </div>
        </div>
        <div
          id="carousel_card_07"
          className="carousel_card"
          onDragStart={handleDragStart}
        >
          <BalanceIcon />
          <h4>Equilíbrio Econômico-Financeiro</h4>
          <div className="atuacao_carousel_card_text">
            Atuação em pleitos de reequilíbrio econômico-financeiro de
            contratos, desde a propositura até sua conclusão, provendo análise
            jurídica e cálculo do montante devido.
          </div>
        </div>
        <div
          id="carousel_card_08"
          className="carousel_card"
          onDragStart={handleDragStart}
        >
          <NoteIcon />
          <h4>Cursos de Capacitação</h4>
          <div className="atuacao_carousel_card_text">
            Cursos personalizados de capacitação e aprimoramento em PPP's e
            Concessões para agentes públicos e empresas privadas que se
            relacionam com a Administração Pública.
          </div>
        </div>
      </Carousel>
    );
  };

  return (
    <div id="atuacao" className="atuacao_wrapper">
      <div className="atuacao_carousel_wrapper">
        <div className="atuacao_title">Áreas de Atuação</div>
        <MultiItemCarousel />
      </div>
      <Setores />
    </div>
  );
};

export default Atuacao;
