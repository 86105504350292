import React from "react";
import "./style.scss";

//imagens
import MACard from "../../assets/logo_MA.png";
import { ReactComponent as ClientesDetail } from "../../assets/clientes_bg_detail.svg";
import { ReactComponent as ClientesBGMobile } from "../../assets/clientes_bg_mobile.svg";

const Clientes = () => {
  return (
    <div id="clientes" className="clientes_wrapper">
      <div className="clientes_title">Nossos Clientes</div>
      <div className="clientes_card_wrapper">
        <ClientesDetail className="clientes_bg" />
        <ClientesBGMobile className="clientes_bg_mobile" />
        <div className="clientes_card_container">
          <div className="clientes_card_border">
            <img src={MACard} alt="" className="clientes_card" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clientes;
