import React from "react";
import "./App.css";
import Home from "./components/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter as Router } from "react-router-dom";
import Sobre from "./components/Sobre/Sobre";
import Atuacao from "./components/Atuacao/Atuacao";
import Clientes from "./components/Clientes/Clientes";
import Parceiros from "./components/Parceiros/Parceiros";
import Contato from "./components/Contato/Contato";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Home />
        <Sobre />
        <Atuacao />
        <Clientes />
        <Parceiros />
        <Contato />
        <Footer />
      </Router>
    </>
  );
}

export default App;
