import React from "react";
import "./style.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//icons
import { ReactComponent as TransportesIcon } from "../../assets/transportes_icon.svg";
import { ReactComponent as EnergiaIcon } from "../../assets/energia_icon.svg";
import { ReactComponent as PortosIcon } from "../../assets/portos_icon.svg";
import { ReactComponent as AeroportosIcon } from "../../assets/aeroportos_icon.svg";
import { ReactComponent as SaneamentoIcon } from "../../assets/saneamento_icon.svg";
import { ReactComponent as OleoIcon } from "../../assets/oleo_icon.svg";
import { ReactComponent as TelecomIcon } from "../../assets/telecom_icon.svg";
import { ReactComponent as ParquesIcon } from "../../assets/parques_icon.svg";
import { ReactComponent as PrevButtonIcon } from "../../assets/expand_circle_left.svg";
import { ReactComponent as NextButtonIcon } from "../../assets/expand_circle_right.svg";
import IconButton from "@mui/material/IconButton";

const Setores = () => {
  const handleDragStart = (e: any) => e.preventDefault();

  const PrevButton = ({ onClick }: any) => {
    return (
      <IconButton
        className="iconButton"
        style={{
          right: "200px",
        }}
        onClick={onClick}
      >
        <PrevButtonIcon className="prevButton" />
      </IconButton>
    );
  };
  const NextButton = ({ onClick }: any) => {
    return (
      <IconButton
        className="iconButton"
        style={{
          right: "100px",
        }}
        onClick={onClick}
      >
        <NextButtonIcon className="prevButton" />
      </IconButton>
    );
  };

  const MultiItemCarousel = () => {
    return (
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={1500}
        autoPlay
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass="react-multi-carousel-item"
        keyBoardControl
        minimumTouchDrag={80}
        customLeftArrow={<PrevButton />}
        customRightArrow={<NextButton />}
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={true}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1500,
            },
            items: 3,
            partialVisibilityGutter: 40,
          },
          hdScreen: {
            breakpoint: {
              max: 1500,
              min: 1024,
            },
            items: 4,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        <div
          id="setores_card_01"
          className="setores_card"
          onDragStart={handleDragStart}
        >
          <TransportesIcon className="setores_icon" />
          <h2>Transportes</h2>
        </div>
        <div
          id="setores_card_02"
          className="setores_card"
          onDragStart={handleDragStart}
        >
          <EnergiaIcon className="setores_icon" />
          <h2>Energia</h2>
        </div>
        <div
          id="setores_card_03"
          className="setores_card"
          onDragStart={handleDragStart}
        >
          <PortosIcon className="setores_icon" />
          <h2>Portos</h2>
        </div>
        <div
          id="setores_card_04"
          className="setores_card"
          onDragStart={handleDragStart}
        >
          <AeroportosIcon className="setores_icon" />
          <h2>Aeroportos</h2>
        </div>
        <div
          id="setores_card_05"
          className="setores_card"
          onDragStart={handleDragStart}
        >
          <SaneamentoIcon className="setores_icon" />
          <h2>Saneamento Básico</h2>
        </div>
        <div
          id="setores_card_06"
          className="setores_card"
          onDragStart={handleDragStart}
        >
          <OleoIcon className="setores_icon" />
          <h2>Óleo e Gás</h2>
        </div>
        <div
          id="setores_card_07"
          className="setores_card"
          onDragStart={handleDragStart}
        >
          <TelecomIcon className="setores_icon" />
          <h2>Telecomunicações</h2>
        </div>
        <div
          id="setores_card_08"
          className="setores_card"
          onDragStart={handleDragStart}
        >
          <ParquesIcon className="setores_icon" />
          <h2>Parques</h2>
        </div>
      </Carousel>
    );
  };

  return (
    <div id="setores" className="setores_wrapper">
      <div className="setores_carousel_wrapper">
        <div className="setores_title">Setores</div>
        <MultiItemCarousel />
      </div>
      <div className="setores_mobile">
        <div className="setores_title">Setores</div>
        <div className="setores_card_container">
          <div>
            <div
              id="setores_card_01"
              className="setores_card"
              onDragStart={handleDragStart}
            >
              <TransportesIcon className="setores_icon" />
              <h2>Transportes</h2>
            </div>
          </div>
          <div>
            <div
              id="setores_card_02"
              className="setores_card"
              onDragStart={handleDragStart}
            >
              <EnergiaIcon className="setores_icon" />
              <h2>Energia</h2>
            </div>
          </div>
        </div>
        <div className="setores_card_container">
          <div>
            <div
              id="setores_card_03"
              className="setores_card"
              onDragStart={handleDragStart}
            >
              <PortosIcon className="setores_icon" />
              <h2>Portos</h2>
            </div>
          </div>
          <div>
            <div
              id="setores_card_04"
              className="setores_card"
              onDragStart={handleDragStart}
            >
              <AeroportosIcon className="setores_icon" />
              <h2>Aeroportos</h2>
            </div>
          </div>
        </div>
        <div className="setores_card_container">
          <div>
            <div
              id="setores_card_05"
              className="setores_card"
              onDragStart={handleDragStart}
            >
              <SaneamentoIcon className="setores_icon" />
              <h2>Saneamento Básico</h2>
            </div>
          </div>
          <div>
            <div
              id="setores_card_06"
              className="setores_card"
              onDragStart={handleDragStart}
            >
              <OleoIcon className="setores_icon" />
              <h2>Óleo e Gás</h2>
            </div>
          </div>
        </div>
        <div className="setores_card_container">
          <div>
            <div
              id="setores_card_07"
              className="setores_card"
              onDragStart={handleDragStart}
            >
              <TelecomIcon className="setores_icon" />
              <h2>Telecomunicações</h2>
            </div>
          </div>
          <div>
            <div
              id="setores_card_08"
              className="setores_card"
              onDragStart={handleDragStart}
            >
              <ParquesIcon className="setores_icon" />
              <h2>Parques</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setores;
