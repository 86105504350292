import React from "react";
import "./style.scss";

//imagens
import { ReactComponent as SobreImage } from "../../assets/sobre_image.svg";
import { ReactComponent as SobreImageMobile } from "../../assets/sobre_bg_mobile.svg";

const Sobre = () => {
  return (
    <div id="sobre" className="sobre_wrapper">
      <div className="sobre_container">
        <SobreImageMobile className="sobre_bg_mobile"/>
      <div className="sobre_logo">
        <SobreImage />
      </div>
      <div className="sobre_text_wrapper">
        <h1 className="sobre_title">Sobre Nós</h1>
        <h4 className="sobre_text">
          Nosso propósito é conectar os segmentos governamental e empresarial no
          Brasil, reunindo conhecimentos do mercado, governo e comunidade.
        </h4>
        <h4 className="sobre_text">
          Desenvolvemos projetos em todas as regiões do Brasil, com soluções
          estratégicas sempre visando o desenvolvimento nacional.
        </h4>
        <h4 className="sobre_text">
          Atuamos em parceria com governos, concessionárias, companhias privadas
          e estatais, estabelecendo parcerias público-privadas e elaborando
          estratégias de planejamento para projetos de natureza social e
          econômica complexos.
        </h4>
      </div>
      </div>
    </div>
  );
};

export default Sobre;
