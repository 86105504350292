import React from "react";
import "./style.scss";

//imagens
import { ReactComponent as BNDESCard } from "../../assets/bndes_card.svg";
import { ReactComponent as ABDIBCard } from "../../assets/abdib_card.svg";
import PPPCard from "../../assets/ppp_card.png";
import { ReactComponent as BIDCard } from "../../assets/bid_card.svg";
import { ReactComponent as ParceirosDetail } from "../../assets/parceiros_bg_detail.svg";
import { ReactComponent as ParceirosBGMobileLeft } from "../../assets/parceiros_bg_mobile_left.svg";
import { ReactComponent as ParceirosBGMobileRight } from "../../assets/parceiros_bg_mobile_right.svg";

const Parceiros = () => {
  return (
    <div id="parceiros" className="parceiros_wrapper">
      <ParceirosDetail className="parceiros_bg" />
      <ParceirosBGMobileLeft
        id="parceiros_bg_mobile_left"
        className="parceiros_bg_mobile"
      />
      <ParceirosBGMobileRight
        id="parceiros_bg_mobile_right"
        className="parceiros_bg_mobile"
      />
      <div className="parceiros_title">Parceiros</div>
      <div className="parceiros_card_wrapper">
        <BNDESCard className="parceiros_card" />
        <ABDIBCard className="parceiros_card" />
        <img src={PPPCard} className="parceiros_card" alt="" />
        <BIDCard className="parceiros_card" />
      </div>
    </div>
  );
};

export default Parceiros;
