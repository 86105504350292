import React from "react";
import "./style.scss";
import { ReactComponent as NavLogo } from "../../assets/nav_logo.svg";
import { Link } from "react-scroll";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import { MenuItem } from "@mui/material";

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (e: any) => {
    setAnchorElNav(e.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <div className="nav_wrapper">
        <div className="nav_container">
          <NavLogo className="nav_logo" />
          <div className="nav_menu">
            <Link to="home" smooth hashSpy className="nav_link">
              Início
            </Link>
            <Link to="sobre" smooth spy hashSpy className="nav_link">
              Sobre
            </Link>
            <Link to="atuacao" smooth spy hashSpy className="nav_link">
              Áreas de Atuação
            </Link>
            <Link to="setores" smooth spy hashSpy className="nav_link">
              Setores
            </Link>
            <Link to="contato" smooth spy hashSpy className="nav_link">
              Contato
            </Link>
          </div>
        </div>
      </div>
      <div className="nav_wrapper_mobile">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="static"
            sx={{
              paddingTop: "40px",
              paddingLeft: "10px",
              paddingRight: "10px",
              background: "transparent",
              position: "absolute",
              boxShadow: "0",
              justifyContent: "center",
            }}
          >
            <Toolbar disableGutters>
              <Box sx={{ flexGrow: 1 }}>
                <NavLogo className="nav_logo" />
              </Box>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
              >
                <MenuIcon sx={{ fontSize: "40px" }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                keepMounted
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                <MenuItem>
                  <Link
                    to="sobre"
                    onClick={handleCloseNavMenu}
                    smooth
                    spy
                    hashSpy
                    className="nav-link"
                  >
                    Sobre
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    to="atuacao"
                    onClick={handleCloseNavMenu}
                    smooth
                    spy
                    hashSpy
                    className="nav-link"
                  >
                    Áreas de Atuação
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    to="setores"
                    onClick={handleCloseNavMenu}
                    smooth
                    spy
                    hashSpy
                    className="nav-link"
                  >
                    Setores
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    to="clientes"
                    onClick={handleCloseNavMenu}
                    smooth
                    spy
                    hashSpy
                    className="nav-link"
                  >
                    Nossos Clientes
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    onClick={handleCloseNavMenu}
                    to="parceiros"
                    smooth
                    spy
                    hashSpy
                    className="nav-link"
                  >
                    Parceiros
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    to="contato"
                    onClick={handleCloseNavMenu}
                    smooth
                    spy
                    hashSpy
                    className="nav-link"
                  >
                    Contato
                  </Link>
                </MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>
        </Box>
      </div>
    </>
  );
};

export default Navbar;

// import * as React from "react";
// import AppBar from "@mui/material/AppBar";
// import Box from "@mui/material/Box";
// import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
// import Typography from "@mui/material/Typography";
// import Menu from "@mui/material/Menu";
// import MenuIcon from "@mui/icons-material/Menu";
// import Container from "@mui/material/Container";
// import MenuItem from "@mui/material/MenuItem";
// import { AllInclusive } from "@mui/icons-material";
// import { Link } from "react-router-dom";
// import "./style.scss";

// const Navbar = () => {
//   const [anchorElNav, setAnchorElNav] = React.useState(null);

//   const handleOpenNavMenu = (e: any) => {
//     setAnchorElNav(e.currentTarget);
//   };

//   const handleCloseNavMenu = () => {
//     setAnchorElNav(null);
//   };

//   return (
//     <AppBar sx={{ background: "transparent" }} className="appbar">
//       <Container>
//         <Toolbar disableGutters>
//           {/* navbar mobile view */}
//           <Link
//             to="/"
//             style={{
//               textDecoration: "none",
//               color: "inherit",
//               display: "flex",
//               flexGrow: 1,
//             }}
//           >
//             Barros
//           </Link>
//           <Box
//             sx={{
//               flexGrow: 1,
//               display: { xs: "flex", md: "none", justifyContent: "flex-end" },
//             }}
//           >
//             <Menu
//               id="menu-appbar"
//               anchorEl={anchorElNav}
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "left",
//               }}
//               keepMounted
//               transformOrigin={{
//                 vertical: "top",
//                 horizontal: "right",
//               }}
//               open={Boolean(anchorElNav)}
//               onClose={handleCloseNavMenu}
//               sx={{
//                 display: { xs: "block", md: "none" },
//               }}
//             >
//               <MenuItem sx={{ backgroundColor: "#222" }}>
//                 <Typography>
//                   <Link
//                     to="/"
//                     style={{ textDecoration: "none", color: "springgreen" }}
//                   >
//                     Início
//                   </Link>
//                 </Typography>
//               </MenuItem>
//               <MenuItem>
//                 <Typography>
//                   <Link
//                     to="/sobre"
//                     style={{ textDecoration: "none", color: "springgreen" }}
//                   >
//                     Sobre
//                   </Link>
//                 </Typography>
//               </MenuItem>
//               <MenuItem>
//                 <Typography>
//                   <Link
//                     to="/projetos"
//                     style={{ textDecoration: "none", color: "springgreen" }}
//                   >
//                     Projetos
//                   </Link>
//                 </Typography>
//               </MenuItem>
//               <MenuItem>
//                 <Typography>
//                   <Link
//                     to="/contato"
//                     style={{ textDecoration: "none", color: "springgreen" }}
//                   >
//                     Contato
//                   </Link>
//                 </Typography>
//               </MenuItem>
//             </Menu>
//             <IconButton
//               size="large"
//               aria-label="account of current user"
//               aria-controls="menu-appbar"
//               aria-haspopup="true"
//               onClick={handleOpenNavMenu}
//               color="inherit"
//             >
//               <MenuIcon />
//             </IconButton>
//           </Box>

//           {/* navbar desktop view */}
//           <Link
//             to="/"
//             style={{
//               textDecoration: "none",
//               color: "inherit",
//               display: "flex",
//             }}
//           >
//             <MenuItem sx={{ ":hover": { color: "springgreen" } }}>
//               <AllInclusive
//                 sx={{
//                   display: { xs: "none", md: "flex" },
//                   mr: 1,
//                 }}
//               />
//               <Typography
//                 variant="h5"
//                 noWrap
//                 sx={{
//                   display: { xs: "none", md: "flex" },
//                   fontFamily: "monospace",
//                   fontWeight: 700,
//                   letterSpacing: ".3rem",
//                   color: "inherit",
//                   textDecoration: "none",
//                 }}
//               >
//                 IT.
//               </Typography>
//             </MenuItem>
//           </Link>
//           <Box
//             sx={{
//               flexGrow: 1,
//               display: { xs: "none", md: "flex" },
//               justifyContent: "flex-end",
//               alignItems: "center",
//             }}
//           >
//             <Link to="/" className="link">
//               Início
//             </Link>

//             <Link to="/sobre" className="link">
//               Sobre
//             </Link>

//             <Link to="/projetos" className="link">
//               Projetos
//             </Link>

//             <Link to="/contato" className="link">
//               Contato
//             </Link>
//           </Box>
//         </Toolbar>
//       </Container>
//     </AppBar>
//   );
// };

// export default Navbar;
