import React from "react";
import "./style.scss";
import "material-icons/iconfont/outlined.css";

//image
import { ReactComponent as FooterLogo } from "../../assets/footer_logo.svg";

//icons
import { ReactComponent as CallIcon } from "../../assets/call.svg";
import { ReactComponent as MailIcon } from "../../assets/mail.svg";
import { ReactComponent as InstagramIcon } from "../../assets/instagram_icon.svg";
import { ReactComponent as FacebookIcon } from "../../assets/facebook_icon.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/linkedin_icon.svg";
import { ReactComponent as LocationIcon } from "../../assets/location_on.svg";

const Footer = () => {
  return (
    <>
      <div className="footer_wrapper">
        <FooterLogo className="footer_logo" />
        <div className="footer_contatos_wrapper">
          <div className="footer_contatos_card">
            <h4>Entre em contato conosco!</h4>
            <div className="footer_contatos_field">
              <CallIcon className="footer_icon" />
              <a href="tel:98985600364">(98) 9 8560-0364</a>
            </div>
            <div className="footer_contatos_field">
              <MailIcon className="footer_icon" />
              <a href="mailto:barrosconsultoriaeconcessoes@gmail.com">
                barrosconsultoriaeconcessoes@gmail.com
              </a>
            </div>
          </div>
          <div className="footer_socialmedia_card">
            <h4>Siga nossas redes sociais!</h4>
            <div className="socialmedia_icon_wrapper">
              <a
                href="https://www.instagram.com/barrosconsultoriaeconcessoes/"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon
                  id="footer_instagram_icon"
                  className="socialmedia_icon"
                />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61552621997388"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon className="socialmedia_icon" />
              </a>
              <a
                href="https://www.linkedin.com/company/barros-consultoria-concessoes/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedinIcon className="socialmedia_icon" />
              </a>
            </div>
          </div>
        </div>
        <div className="footer_location_wrapper">
          <a
            href="https://maps.app.goo.gl/Qcd4RZKsjYjw6VsY6"
            target="_blank"
            rel="noreferrer"
            className="footer_contatos_field"
          >
            <LocationIcon />
            Maranhão, Brasil
          </a>
          <p>Barros Consultoria e Concessões, 2023.</p>
        </div>
      </div>
      <div className="footer_end_bar" />
    </>
  );
};

export default Footer;
