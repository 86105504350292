import React from "react";
import "./style.scss";
import { ReactComponent as BarrosLogo } from "../../assets/barros_logo.svg";


const Home = () => {
  return (
    <div id="home" className="home_wrapper">
      <div className="home_container">
        <div className="logo_container">
          <BarrosLogo className="home_logo" />
          <h4 className="home_logo_text">
            Construindo um futuro mais eficiente.
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Home;
