import "./style.scss";
import ContatoForm from "./ContatoForm";

//imagem
import { ReactComponent as ContatoDetail } from "../../assets/contato_bg_detail.svg";
//icon
import { ReactComponent as VoltarButton } from "../../assets/voltar_button.svg";
import { Link } from "react-scroll";
import { Button } from "@mui/material";

const Contato = () => {
  return (
    <div id="contato" className="contato_wrapper">
      <ContatoDetail className="contato_detail" />
      <div className="contato_card_wrapper">
        <div className="contato_card_container">
          <div className="contato_title">Contato</div>
          <div className="contato_form_container">
            <ContatoForm />
          </div>
        </div>

        <Link to="home" smooth className="contato_voltar_button">
          <Button>
            <p>Voltar ao topo</p> <VoltarButton />
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Contato;
